import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from "../components/layout"
import ArticlePreview from '../components/article-preview'

class NewsIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulNoticia.edges')

    return (
      <Layout>
        <Helmet>
          <title>R1 News | {siteTitle}</title>
          <meta name="description" content="Consulta las últimas novedades en R1 News." />
        </Helmet>
        <div className="hero-text">
          News
        </div>
        <div className="wrapper">
          <ul className="article-list row reset-list">
            {posts.map(({ node }) => {
              return (
                <li className="columns small-12 medium-6 large-4" key={node.slug}>
                  <ArticlePreview article={node} />
                </li>
              )
            })}
          </ul>
        </div>
      </Layout>
    )
  }
}

export default NewsIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulNoticia(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          titulo
          slug
          createdAt(formatString: "DD/MM/YYYY")
          imagen_preview {
            file {
              url
            }
          }
          texto_preview {
            texto_preview
          }
        }
      }
    }
  }
`
